/* Entry point for your PostCSS build */

@import "tom-select/dist/css/tom-select.bootstrap5.css";

@import "tailwindcss/base";
/*@import "./theme/base.scss"; */
@import "tailwindcss/components";
/*@import "./theme/components.scss"; */
@import "tailwindcss/utilities";
/*@import "./theme/sections.scss"; */

/* Theme */
/*@import "./theme/style.scss"; */

/* Custom Theme overrides */
/*@import "./theme/overrides.scss"; */

@import "./app/cookieconsent.css";
@import "./app/pagy.scss";
@import "./app/trix_content.scss";

.turbo-progress-bar {
  background: #15803d;
}
