@import "trix/dist/trix";

.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment, .trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

.trix-content {
  @apply w-full;
}

.trix-content h1 {
  font-size: 1.25rem !important;
  line-height: 1.25rem !important;
  @apply leading-5 font-semibold mb-4;
}

.trix-content a:not(.no-underline) {
  @apply underline;
}

.trix-content a:visited {
  color: blue;
}

.trix-content ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.trix-content ol {
  list-style-type: decimal;
  padding-left: 1rem;
}

.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 1.5em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}

.trix-content blockquote {
  border: 0 solid #ccc;
  border-left-width: 0px;
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em;
}
