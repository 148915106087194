/* https://ddnexus.github.io/pagy/docs/api/stylesheets/ */

.pagy {
  @apply inline-flex items-stretch -space-x-px mt-5 ml-5;

  > *:first-child {
    @apply rounded-l-lg;
  }

  > *:last-child {
    @apply rounded-r-lg;
  }
}

.pagy a:not(.gap) {
  @apply flex items-center justify-center text-sm py-2 px-4 leading-tight text-gray-500 bg-white border border-gray-300;

  &:hover {
    @apply bg-gray-100 text-gray-700;
  }

  &:not([href]) {
    @apply text-gray-300 bg-gray-100 cursor-default;
  }

  &.current {
    @apply flex items-center justify-center text-sm z-10 py-2 px-4 leading-tight border-primary-500 bg-indigo-50 text-primary-600 font-bold;
  }
}

span.pagy.info {
  @apply text-sm font-normal text-gray-500;
}
